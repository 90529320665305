import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from 'react-datepicker';

import styled from '@emotion/styled';
import { useAuthDataContext } from '../../components/AuthProvider';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
//////////////////////////////////

import { getAllContractByDays } from '../../utils/authApi';

const RootStyle = styled('div')({
  height: '80vh',
  display: 'grid',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

const New = () => {
  const navigate = useNavigate();
  const { authData, isdemo } = useAuthDataContext();
  useEffect(() => {
    if (isdemo || !authData.email) {
      navigate('/');
    }
  }, [authData, isdemo]);
  const [startDate, setStartDate] = useState(new Date());
  const [customer, setCustomer] = useState('');
  const [dates, setDates] = useState([]);

  useEffect(() => {
    getAllContractByDays({
      customer: customer,
      creator: authData.email,
    })
      .then(({ data }) => {
        setDates(data);
      })
      .catch((err) => {});
  }, [startDate, customer, authData]);

  const months = {
    '01': '1',
    '02': '2',
    '03': '3',
    '04': '4',
    '05': '5',
    '06': '6',
    '07': '7',
    '08': '8',
    '09': '9',
    '10': '10',
    '11': '11',
    '12': '12',
  }
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          {/* <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          /> */}
          <TextField
            id="input-with-icon-textfield"
            label="Search for name or email"
            value={customer}
            onChange={(e) => setCustomer(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {dates.length && (
            <List
              component="nav"
              aria-label="main mailbox folders"
              style={{ marginBottom: 100 }}
            >
              {dates.map((data) => {
                return (
                <ListItemButton
                  onClick={(event) =>
                    navigate(
                      `/open/${data.date.split('-')[0]}-${months[data.date
                        .split('-')[1]]}`
                    )
                  }
                  sx={{ padding: 1 }}
                  key={data.date}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${data.date.split('-')[1]}/${
                      data.date.split('-')[0]
                    }`}
                    secondary={`${data.count} contracts`}
                  />
                </ListItemButton>
              )})}
            </List>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default New;
