import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { updateDataWindowworld, updateValue } from '../../../store/slices/windowworldSlice';
import { updateData } from '../../../store/slices/optionSlice';
import { updateDataGlassoption } from '../../../store/slices/glassoptionSlice';
import { updateDataMiscellenous } from '../../../store/slices/miscellenousSlice';
import { updateDataWindowoption } from '../../../store/slices/windowoptionSlice';
import { updateMainTable as updateMainTableForWindowOrder } from '../../../store/slices/windoworderSlice';
import { Watch } from 'react-loader-spinner';
import Loader from 'react-loader-advanced';

import PageWrapper from '../../../components/PageWrapper';
import Summary from '../../../components/Measure/Summary';
import { useAuthDataContext } from '../../../components/AuthProvider';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { fractionCalculator, parseFraction } from '../../../utils/globals';
import styled from 'styled-components';
import { initDataOfWindowOrder } from '../../../constants/variables';

import '../../../styles/base.css';
import '../../../App.css';

const AppWrapper = styled.div`
  padding: 100px 0 0 0;
  background-color: white;
  margin-top: -50px;
`;
let data;
const dataForWindowOrder = {
  mainTable: {},
};
const MeasureSheetPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const measuresheetData = useSelector((state) => state.measuresheet.data);
  const windowOrderData = useSelector((state) => state.windoworder.data);
  dataForWindowOrder.mainTable = { ...windowOrderData.mainTable };
  let windowworldData = useSelector((state) => state.windowworld.data);
  let miscellData = useSelector((state) => state.miscellenous.data);
  let windowoptionData = useSelector((state) => state.windowoption.data);
  let midmis = { ...miscellData, miscellenousInput5: 0 };
  let midgo = { glassOptionInput1: 0, glassOptionInput2: 0 };
  let midwop = {
    ...windowoptionData,
    windowOptionInput1: 0,
    windowOptionInput2: 0,
    windowOptionInput7: 0,
    windowOptionInput12: '',
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let midww = {
    ...windowworldData,
    windowTotal: 0,
    amount: 0,
    windowWorldInput1: '',
    windowWorldInput2: '',
    windowWorldInput3: '',
    windowWorldInput4: '',
    windowWorldInput5: '',
    windowWorldInput6: '',
    windowWorldInput7: '',
    windowWorldInput8: '',
    windowWorldInput9: '',
    windowWorldInput10: '',
    windowWorldInput11: '',
    windowWorldInput12: '',
    windowWorldInput13: '',
    windowWorldInput14: '',
    windowWorldInput15: '',
    windowWorldInput16: '',
    windowWorldInput17: 0,
    windowWorldInput18: 0,
    windowWorldInput19: 0,
    windowWorldInput20: 0,
    windowWorldInput21: 0,
    windowWorldInput22: '',
    windowWorldInput23: '',
    windowWorldInput24: '',
    windowWorldInput25: '',
    windowWorldInput26: '',
    windowWorldInput27: '',
    windowWorldInput28: '',
    windowWorldInput29: '',
    windowWorldInput30: '',
    windowWorldInput31: 'WHITE',
    windowWorldInput32: 'WHITE',
    windowWorldInput33: '',
  };
  data = { ...measuresheetData.mainTable };

  const populateMainTable = () => {
    let orderWHCountfor102 = 0,
      orderWHCountfor121 = 0,
      styleCount = {
        DH: { value: 0, mapId: 'windowWorldInput1', weight: 1 },
        DH2: { value: 0, mapId: 'windowWorldInput1', weight: 2 },
        DH3: { value: 0, mapId: 'windowWorldInput1', weight: 3 },
        DH_6: { value: 0, mapId: 'windowWorldInput6', weight: 1 },
        DH2_6: { value: 0, mapId: 'windowWorldInput6', weight: 2 },
        DH3_6: { value: 0, mapId: 'windowWorldInput6', weight: 3 },
        PW: { value: 0, mapId: 'windowWorldInput4', weight: 1 },
        'PW(CAS)': { value: 0, mapId: 'windowWorldInput4', weight: 1 },
        PW_6: { value: 0, mapId: 'windowWorldInput9', weight: 1 },
        'PW(CAS)_6': { value: 0, mapId: 'windowWorldInput9', weight: 1 },
        'CAS-L': { value: 0, mapId: 'windowWorldInput13', weight: 1 },
        'CAS-R': { value: 0, mapId: 'windowWorldInput13', weight: 1 },
        CAS2: { value: 0, mapId: 'windowWorldInput14', weight: 1 },
        CAS3: { value: 0, mapId: 'windowWorldInput15', weight: 1 },
        AWN: { value: 0, mapId: 'windowWorldInput12', weight: 1 },
        SLD: { value: 0, mapId: 'windowWorldInput2' },
        '2LS': { value: 0, mapId: 'windowWorldInput2', weight: 1 },
        '3LS': { value: 0, mapId: 'windowWorldInput3', weight: 1 },
        '2LS_6': { value: 0, mapId: 'windowWorldInput7', weight: 1 },
        '3LS_6': { value: 0, mapId: 'windowWorldInput8', weight: 1 },
        TRN: { value: 0, mapId: 'windowWorldInput5', weight: 1 },
        HOP: { value: 0, mapId: 'windowWorldInput16', weight: 1 },
        SP: { value: 0, mapId: 'windowWorldInput18', weight: 1 },
        HR: { value: 0, mapId: 'windowWorldInput19', weight: 1 },
        SPD: { value: 0, mapId: '', weight: 1 },
        OCT: { value: 0, mapId: '', weight: 1 },
      };
    // let realCount = {};
    let SolarZone = 0,
      SolarZoneTG = 0,
      MullMM = 0,
      GridsCount = 0,
      ColorExtCount = 0,
      aceCount = 0,
      obscCount = 0,
      woodgainCount = 0,
      formEnhanceCount = 0,
      almclayCount = 0,
      prigeCount = 0,
      flexMap = {};
    
    const spmap = [{count: 'windowWorldInput23', price: 'windowWorldInput18', cval: "", pval: "", mapoct: "windowWorldInput27", mapval: ""},
      {count: 'windowWorldInput24', price: 'windowWorldInput19', cval: "", pval: "", mapoct: "windowWorldInput28", mapval: ""},
      {count: 'windowWorldInput25', price: 'windowWorldInput20', cval: "", pval: "", mapoct: "windowWorldInput29", mapval: ""}];
    let spmapindex = 0;
    Object.entries(data).forEach(([k, v]) => {
      ////////Oversized window
      const sum = parseFraction(
        fractionCalculator(v.orderHeight, '+', v.orderWidth)
      );
      
      if (v.style == "SP" || v.style == "OCT" || v.style == "HR") {
        spmap[spmapindex].cval = 1;
        spmap[spmapindex].pval = 10*(parseFloat(v.roWidth) + parseFloat(v.roHeight));
        if(v.style == "OCT") {
          spmap[spmapindex].mapval = `${v.roWidth}-inch Octagon`;
        }
        if(v.style == "HR") {
          spmap[spmapindex].mapval = `${v.roWidth}-inch Half Round`;
        }
        spmapindex++;
      }

      if (sum >= 102 && sum < 121 && v.style != "PW" && v.style != "2LS" && v.style != "3LS") {
        if (v.foam || v.energy === 'IMPACT 366') {
          styleCount[v.style + '_6'] = styleCount[v.style + '_6'] || {};
          orderWHCountfor102 += styleCount[v.style + '_6'].weight || 1;
        } else orderWHCountfor102 += styleCount[v.style].weight;
      }
      if (sum >= 121 && sum < 156  && v.style != "2LS" && v.style != "3LS") {
        if (v.foam || v.energy === 'IMPACT 366') {
          styleCount[v.style + '_6'] = styleCount[v.style + '_6'] || {};
          orderWHCountfor121 += styleCount[v.style + '_6'].weight || 1;
        } else orderWHCountfor121 += styleCount[v.style].weight;
      }
      ////////CountStyle
      if (v.style) {
        if (v.foam || v.energy === 'IMPACT 366') {
          styleCount[v.style + '_6'] = styleCount[v.style + '_6'] || {};
          styleCount[v.style + '_6'].value +=
            styleCount[v.style + '_6'].weight || 1;
        } else styleCount[v.style].value += styleCount[v.style].weight;
      }
      /////////////
      if (v.energy === 'LOE SZONE') {
        if (v.foam || v.energy === 'IMPACT 366') {
          styleCount[v.style + '_6'] = styleCount[v.style + '_6'] || {};
          SolarZone += styleCount[v.style + '_6']?.weight || 1;
        } else SolarZone += styleCount[v.style].weight;
      }
      if (v.energy === 'HP-LOE SZONE Elite') {
        if (v.foam || v.energy === 'IMPACT 366') {
          styleCount[v.style + '_6'] = styleCount[v.style + '_6'] || {};
          SolarZoneTG += styleCount[v.style + '_6'].weight || 1;
        } else SolarZoneTG += styleCount[v.style].weight;
      }
      if (v.style === "DH2") {
        MullMM ++;
      }
      if (v.style === "DH3") {
        MullMM +=2;
      }
      if (v.style === "CAS2") {
        MullMM ++;
      }
      if (v.style === "CAS3") {
        MullMM +=2;
      } 
      if (v.mullCuts) {
        MullMM += Number(v.mullCuts);
      }
      ///////////////WindowOption
      if (new RegExp('[0-9]/[0-9]', 'g').test(v.grids)) {
        if (v.foam || v.energy === 'IMPACT 366') {
          styleCount[v.style + '_6'] = styleCount[v.style + '_6'] || {};
          GridsCount += styleCount[v.style + '_6'].weight || 1;
        } else GridsCount += styleCount[v.style].weight;
      }
      ColorExtCount +=
        v.extColor.split('Painted').length -
        1 +
        v.intColor.split('Painted').length -
        1;
      // if (v.style == 'DH2')
      //   ColorExtCount++;
      // if (v.style == 'DH3')
      //   ColorExtCount+=2;
      if (v.extColor.split('Painted').length - 1)
        flexMap[v.extColor.replace(' - Painted', '')] = 0;
      if (v.intColor.split('Painted').length - 1)
        flexMap[v.intColor.replace(' - Painted', '')] = 0;
      
      if (v.intColor.indexOf('Almond') >= 0 || v.intColor.indexOf('Clay') >= 0 || v.extColor.indexOf('Almond') >= 0 || v.extColor.indexOf('Clay') >= 0)
      {
        if (v.style == 'CAS2')
          aceCount+=2;
        else if (v.style == 'CAS3')
          aceCount+=3;
        else if (v.style == 'DH2')
          aceCount+=2;
        else if (v.style == 'DH3')
          aceCount+=3;
        else aceCount++;
      } else if (v.intColor.indexOf('Extruded') >= 0 && v.intColor === v.extColor)
      {
        if (v.intColor !== 'White - Extruded') aceCount++;
      }
      
      let obscWeight = 1;
      if (v.style == 'CAS2')
        obscWeight++;
      if (v.style == 'CAS3')
        obscWeight+=2;
      if (v.style == 'DH2')
        obscWeight++;
      if (v.style == 'DH3')
        obscWeight+=2;

      if (v.obsc == 'YES')
        obscCount+=obscWeight;
      if (v.obsc == 'BOTH')
        obscCount+=2*obscWeight;
      if (v.obsc == 'BSO')
        obscCount+=obscWeight;
      if (v.obsc == 'TSO')
        obscCount+=obscWeight;

      if(v.grids.toLowerCase() == 'prairie')
        prigeCount++;
      if (v.intColor.indexOf('Colonial Cherry') >= 0)
        if(v.style == 'DH2')
          woodgainCount+=2;
        else if(v.style == 'DH3')
          woodgainCount+=3;
        else woodgainCount++;

      if (v.intColor.indexOf('Natural') >= 0)
        woodgainCount++;
      if (v.intColor.indexOf('Hillside') >= 0)
        woodgainCount++;
      if (v.foam)
        formEnhanceCount++;
      
    });
    ////////Oversized window
    midww['windowWorldInput10'] = orderWHCountfor102 || '';
    midww['windowWorldInput11'] = orderWHCountfor121 || '';
    /////////CountStyle
    Object.values(styleCount).forEach((v) => {
      if (v.value) {
        if (midww[v.mapId] === NaN || !midww[v.mapId]) midww[v.mapId] = 0;
        midww[v.mapId] += v.value;
      }
    });
    dispatch(updateDataWindowworld({ ...midww }));

    spmap.forEach(v => {
      dispatch(updateValue({id: v.count, value: v.cval }));
      dispatch(updateValue({id: v.price, value: v.pval }));
      if(v.mapval) {
        dispatch(updateValue({id: v.mapoct, value: v.mapval }));
      }
    })
    //////////////////////
    midgo['glassOptionInput1'] = SolarZone || '';
    midgo['glassOptionInput2'] = SolarZoneTG || '';
    dispatch(updateDataGlassoption({ ...midgo }));
    //////////////////////

    midmis['miscellenousInput5'] = MullMM || '';
    dispatch(updateDataMiscellenous({ ...midmis }));

    midwop['windowOptionInput1'] = aceCount || '';
    midwop['windowOptionInput2'] = GridsCount || '';
    midwop['windowOptionInput3'] = obscCount || '';
    midwop['windowOptionInput4'] = prigeCount || '';
    midwop['windowOptionInput6'] = woodgainCount || '';
    midwop['windowOptionInput7'] = ColorExtCount || '';
    midwop['windowOptionInput8'] = formEnhanceCount || '';
    midwop['windowOptionInput12'] = Object.keys(flexMap).join('/');
    dispatch(updateDataWindowoption({ ...midwop }));
  };
  const processWindowOrderData = () => {
    Object.keys(dataForWindowOrder.mainTable).forEach((key) => {
      dataForWindowOrder.mainTable[key] = { ...initDataOfWindowOrder };
    });

    Object.values(measuresheetData.mainTable).forEach((ele, key) => {
      if (ele.categoryNum >= 0) {
        dataForWindowOrder.mainTable[key] = {
          ...dataForWindowOrder.mainTable[key],
          categoryNum: key,
          qty: parseInt(dataForWindowOrder.mainTable[key].qty + 1),
          intColor: ele.intColor,
          extColor: ele.extColor,
          type: ele.style,
          szWidth: ele.orderWidth,
          szHeight: ele.orderHeight,
          energy: ele.energy,
          obsc: ele.obsc,
          temp: ele.temp,
          pattern: ele.grids,
          foam: ele.foam,
          grids: ele.grids != '' ? true : false,
        };
      }
    });
    dispatch(updateMainTableForWindowOrder(dataForWindowOrder.mainTable));
  };
  const handleNext = () => {
    populateMainTable();
    // processWindowOrderData();
    navigate('/documentation');
  };

  const lastrow = Object.entries(measuresheetData.mainTable).find(
    ([k, v]) => v.room === '' && v.style === '' && v.notes === ''
  )[0];

  return (
    <div style={{ marginBottom: 150 }}>
      <Stack
        spacing={2}
        direction="row"
        sx={{ marginTop: '7vh', justifyContent: 'space-around', height: 120 }}
      >
        <Button
          variant="contained"
          onClick={() =>
            navigate(
              `/new/customer/:customer/createcontract/measure/${Number(
                lastrow
              )}`
            )
          }
          sx={{ width: 220, fontSize: 35 }}
        >
          Add New
        </Button>
        <Button
          variant="contained"
          onClick={() => handleNext()}
          sx={{ width: 220, fontSize: 35 }}
        >
          Finish
        </Button>
      </Stack>
      <PageWrapper addClass="page">
        <Summary page={1} />
      </PageWrapper>
      <PageWrapper addClass="page">
        <Summary page={2} />
      </PageWrapper>
    </div>
  );
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
}

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authData, isdemo } = useAuthDataContext();
  useEffect(() => {
    if (isdemo || !authData.email) {
      navigate('/');
    }
  }, [authData, isdemo]);

  useWindowDimensions();
  const windowInnerWidth = useRef(window.innerWidth);
  const loading = useSelector((state) => state.operation.loading);
  const dispatch = useDispatch();
  dispatch(updateData({ dataKey: 'viewMode', data: 'homepage' }));

  const style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  const spinner = (
    <div style={style}>
      <Watch
        height="80"
        width="80"
        radius="48"
        color="#4fa94d"
        ariaLabel="watch-loading"
        visible={true}
      />
    </div>
  );

  //  console.log('???=>window.innerWidth using useRef:', windowInnerWidth.current);
  //  console.log('window.innerWidth', window.innerWidth);

  return !isdemo && !authData.email ? (
    <></>
  ) : (
    <>
      <Loader
        backgroundStyle={{
          backgroundColor: 'rgba(0, 0, 0, 0)',
        }}
        show={loading === 'pending' ? true : false}
        message={spinner}
      >
        <div
          className="App"
          style={{
            transform: `scale(${windowInnerWidth.current / 1366})`,
          }}
        >
          <AppWrapper id="appwrapper">
            <Loader show={loading === 'pending' ? true : false} message={<></>}>
              <MeasureSheetPage />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Loader>
          </AppWrapper>
        </div>
      </Loader>
    </>
  );
};

export default HomePage;
